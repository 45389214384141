import * as React from "react";

import { AlbumCarousel } from "@shared-ui/retail-trips-album-carousel";
import { observer } from "mobx-react";
import { TripsAlbumCarouselModuleProps } from "components/flexComponents/TripsAlbumCarousel/typings";

export const TripsAlbumCarousel = observer((props: TripsAlbumCarouselModuleProps) => {
  const { templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const { carouselHeadingSize, lazyLoading } = templateComponent.config;
  return <AlbumCarousel carouselHeadingSize={carouselHeadingSize} lazyLoadImage={lazyLoading} />;
});

export default TripsAlbumCarousel;
